import React, { useState, useEffect } from 'react';
import GhostContentAPI from '@tryghost/content-api';
import '../styles/LandingPage.css';
import '../styles/Books.css';
import bookCover from '../images/MonarchsFinal600DPI55by85.jpg';
import artwork1 from '../images/Cooler Heads 1.jpg';
import artwork2 from '../images/Cooler Heads 2.jpg';
import artwork3 from '../images/Cooler Heads 3.png';

// Initialize the Ghost API client
const api = new GhostContentAPI({
  url: import.meta.env.VITE_GHOST_API_URL,
  key: import.meta.env.VITE_GHOST_API_KEY,
  version: 'v5.0'
});

const LandingPage = ({ onThemeChange }) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [theme, setTheme] = useState('modern');

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const result = await api.posts
          .browse({
            limit: 3,
            fields: ['title', 'excerpt', 'url', 'published_at']
          });
        
        console.log('API Response:', result);
        setPosts(result);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching blog posts:', err);
        setError(`Failed to load blog posts: ${err.message}`);
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  // Add effect to handle theme changes
  useEffect(() => {
    const htmlElement = document.documentElement;
    const bodyElement = document.body;
    
    if (theme === 'modern') {
      htmlElement.classList.add('modern-theme');
      bodyElement.classList.add('modern-theme');
    } else {
      htmlElement.classList.remove('modern-theme');
      bodyElement.classList.remove('modern-theme');
    }
  }, [theme]);

  const handleThemeChange = (newTheme) => {
    setTheme(newTheme);
    onThemeChange(newTheme);
  };

  return (
    <div className={`landing-container ${theme === 'modern' ? 'modern-theme' : ''}`}>
      <div className="theme-toggle">
        <button 
          className={`theme-button ${theme === 'modern' ? 'active' : ''}`}
          onClick={() => handleThemeChange('modern')}
        >
          Modern View
        </button>
        <button 
          className={`theme-button ${theme === 'fun' ? 'active' : ''}`}
          onClick={() => handleThemeChange('fun')}
        >
          Fun View
        </button>
      </div>
      
      <header className="hero-section">
        <h1>Malcolm Stanley</h1>
        <p className="subtitle">Innovation Change Management Leader & Technology Enthusiast</p>
      </header>
      
      <section className="experience-section">
        <h2>Professional Experience</h2>
        <div className="experience-content">
          <p>
            I am a technology leader with over 25 years of experience driving innovation and digital transformation 
            across media, telecommunications, and cloud technologies. My expertise spans cloud computing 
            (AWS-certified in Solutions Architecture and Machine Learning), product management, and business 
            development. I have led numerous successful initiatives including implementing technical operations 
            for a greenfield 5G launch in Japan, driving market acceptance of cloud video SaaS solutions, and 
            leading first-to-market implementations of VOD and DVR technologies that enabled billions in new revenue. 
            Currently, I provide data-driven consulting services, helping organizations optimize their operations 
            and development processes through advanced analytics and machine learning.
          </p>
          <a 
            href="https://www.linkedin.com/in/amstanley/" 
            target="_blank" 
            rel="noopener noreferrer"
            className="linkedin-link"
          >
            View my complete profile on LinkedIn
          </a>
        </div>
      </section>

      <section className="blog-section">
        <h2>Latest Blog Posts</h2>
        {loading && <p style={{ color: '#00ffff', textAlign: 'center' }}>Loading blog posts...</p>}
        {error && <p style={{ color: '#ff0000', textAlign: 'center' }}>{error}</p>}
        <div className="blog-grid">
          {posts.map(post => (
            <article key={post.id} className="blog-post">
              <h3>{post.title}</h3>
              {post.excerpt && (
                <p className="blog-post-excerpt">
                  {post.excerpt.substring(0, 300)}...
                </p>
              )}
              <p className="blog-post-date">
                {new Date(post.published_at).toLocaleDateString()}
              </p>
              <a 
                href={post.url} 
                target="_blank" 
                rel="noopener noreferrer"
                className="blog-post-link"
              >
                Read More
              </a>
            </article>
          ))}
        </div>
      </section>

      <section className="artwork-section">
        <h2>Artwork</h2>
        <div className="artwork-content">
          <p>
            As an artistic pursuit, I built a CNC machine with a Rotary Axis and used that to try and recreate 
            sculptured works of antiquity using wooden beams and firewood as a medium. The results were promising. 
            The next step is to try and upgrade to a 6-axis robotic arm based system so I can improve the fidelity 
            of the artworks that are created and possibly also create them in stone instead of wood.
          </p>
          <div className="artwork-grid">
            <div className="artwork-column">
              <div className="artwork-item">
                <img src={artwork1} alt="CNC Artwork 1" />
              </div>
              <div className="artwork-item">
                <img src={artwork2} alt="CNC Artwork 2" />
              </div>
            </div>
            <div className="artwork-column">
              <div className="artwork-item artwork-item-tall">
                <img src={artwork3} alt="CNC Artwork 3" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="publications-section">
        <h2>Publications</h2>
        <div className="book-container">
          <div className="book-card">
            <h3>Monarchs: A Story of the Near Future</h3>
            <div className="book-content">
              <a href="https://www.amazon.com/Monarchs-Story-Future-Malcolm-Stanley/dp/1973235412" 
                 target="_blank" 
                 rel="noopener noreferrer"
                 className="book-link">
                <div className="book-thumbnail">
                  <div className="book-cover">
                    <img 
                      src={bookCover} 
                      alt="Monarchs: A Story of the Near Future - Book Cover" 
                      className="book-cover-image"
                    />
                  </div>
                </div>
              </a>
              <div className="book-info">
                <div className="book-description">
                  <p>
                    As humanity is slowly succumbing to a mysterious plague, a businessman traverses 
                    the globe seeking escape. This gripping near-future science fiction novel explores 
                    themes of survival, technology, and human nature in a world on the brink of 
                    catastrophic change.
                  </p>
                  <p className="book-details">
                    Published: September 24, 2017<br/>
                    Length: 138 pages<br/>
                    Available on: Kindle & Paperback
                  </p>
                </div>
                <a href="https://www.amazon.com/Monarchs-Story-Future-Malcolm-Stanley/dp/1973235412" 
                   target="_blank" 
                   rel="noopener noreferrer"
                   className="purchase-label">
                  Click here to purchase
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-section">
        <h2>Get in Touch</h2>
        <div className="contact-links">
          <a href="https://github.com/amstanley" target="_blank" rel="noopener noreferrer">GitHub</a>
          <a href="https://www.linkedin.com/in/amstanley/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
          <a href="mailto:a.malcolm.stanley@gmail.com">Email</a>
        </div>
      </section>
    </div>
  );
};

export default LandingPage; 