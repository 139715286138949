import React, { useState, useEffect, useRef } from 'react';
import LandingPage from './components/LandingPage';
import './App.css';

const App = () => {
  const [theme, setTheme] = useState('modern');
  const toasterRefs = useRef([]);
  const reverseToasterRefs = useRef([]);
  const diagonalToasterRefs = useRef([]);

  const handleThemeChange = (newTheme) => {
    setTheme(newTheme);
  };

  useEffect(() => {
    if (theme !== 'modern') {
      // Add animation classes with staggered delays
      toasterRefs.current.forEach((ref, index) => {
        if (ref) {
          setTimeout(() => {
            ref.classList.add('animate');
          }, index * 5000); // 5 second stagger between each toaster
        }
      });

      reverseToasterRefs.current.forEach((ref, index) => {
        if (ref) {
          setTimeout(() => {
            ref.classList.add('animate');
          }, 2500 + index * 6000); // Start after 2.5s with 6 second stagger
        }
      });

      diagonalToasterRefs.current.forEach((ref, index) => {
        if (ref) {
          setTimeout(() => {
            ref.classList.add('animate');
          }, 4000 + index * 8000); // Start after 4s with 8 second stagger
        }
      });
    }
  }, [theme]);

  return (
    <div className={`App ${theme === 'modern' ? 'modern-theme' : ''}`}>
      {theme !== 'modern' && (
        <>
          <div ref={el => toasterRefs.current[0] = el} className="flying-toaster">🍞</div>
          <div ref={el => toasterRefs.current[1] = el} className="flying-toaster">🍞</div>
          <div ref={el => toasterRefs.current[2] = el} className="flying-toaster">🍞</div>
          <div ref={el => toasterRefs.current[3] = el} className="flying-toaster">🍞</div>
          <div ref={el => toasterRefs.current[4] = el} className="flying-toaster">🍞</div>
          
          <div ref={el => reverseToasterRefs.current[0] = el} className="flying-toaster-reverse">🍞</div>
          <div ref={el => reverseToasterRefs.current[1] = el} className="flying-toaster-reverse">🍞</div>
          <div ref={el => reverseToasterRefs.current[2] = el} className="flying-toaster-reverse">🍞</div>
          
          <div ref={el => diagonalToasterRefs.current[0] = el} className="flying-toaster-diagonal">🍞</div>
          <div ref={el => diagonalToasterRefs.current[1] = el} className="flying-toaster-diagonal">🍞</div>
          <div ref={el => diagonalToasterRefs.current[2] = el} className="flying-toaster-diagonal">🍞</div>

          <div className="kitty">🐱</div>
          <div className="kitty">🐱</div>
          <div className="kitty">🐱</div>
          <div className="kitty">🐱</div>
          <div className="kitty">🐱</div>

          <div className="unicorn">🦄</div>
          <div className="unicorn">🦄</div>
          <div className="unicorn">🦄</div>
          <div className="unicorn">🦄</div>
          <div className="unicorn">🦄</div>

          <div className="alien">👾</div>
          <div className="alien">👾</div>
          <div className="alien">👾</div>
          <div className="alien">👾</div>

          <div className="star">⭐</div>
          <div className="star">⭐</div>
          <div className="star">⭐</div>
          <div className="star">⭐</div>

          <div className="rocket">🚀</div>
          <div className="rocket">🚀</div>
          <div className="rocket">🚀</div>

          <div className="planet">🪐</div>
          <div className="planet">🪐</div>
          <div className="planet">🪐</div>

          <div className="crater">🌑</div>
          <div className="crater">🌑</div>
          <div className="crater">🌑</div>
          <div className="crater">🌑</div>
          <div className="crater">🌑</div>
        </>
      )}
      <LandingPage onThemeChange={handleThemeChange} />
    </div>
  );
};

export default App; 